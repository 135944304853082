import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import ThemedBox from "../components/themedBox";
import Hero from "../components/hero";
import { ReactComponent as EventsArtwork } from "../svg/events.svg";
import Gantt from "../components/gantt";

function StandardPageTemplate({ data }) {
  const { title, childContent = [] } = data.contentfulPage;
  const breadcrumbs = [{ name: "Home", href: "/" }, { name: title }];
  return (
    <Layout
      hero={
        <HiddenOverflow>
          <Hero breadcrumbs={breadcrumbs} hasRelativeContainer>
            <Artwork />
            <PageTitle>{title}</PageTitle>
          </Hero>
        </HiddenOverflow>
      }
    >
      <SEO title={title} />
      {/* <Gantt /> */}
      {childContent.map((childContentItem) => {
        const {
          id,
          alternativeName,
          descriptiveTitle,
          name,
          intro,
          page,
          slug,
          events,
        } = childContentItem;
        const trackNameForCss = alternativeName.toLowerCase();
        return (
          <ThemedBox
            key={id}
            trackNameForCss={trackNameForCss}
            alternativeName={alternativeName}
            descriptiveTitle={descriptiveTitle}
            name={name}
            intro={intro.intro}
            events={events}
            moreInfoLink={`/${page[0].slug}/${slug}/`}
          />
        );
      })}
    </Layout>
  );
}

export default StandardPageTemplate;

const HiddenOverflow = styled.div`
  position: relative;
  overflow: hidden;
`;

const Artwork = styled(EventsArtwork)`
  position: absolute;
  top: 0;
  right: -2rem;
  max-width: 50%;
  z-index: -1;
  fill: none !important;
  stroke: ${({ theme }) => theme.palette.white};
  stroke-width: 2;
`;

export const pageQuery = graphql`
  query MainEventPageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      childContent {
        ... on ContentfulTrack {
          id
          descriptiveTitle
          name
          slug
          alternativeName
          intro {
            intro
          }
          page {
            id
            slug
          }
          events {
            id
            title
            startDatetime
            endDatetime
          }
        }
      }
    }
  }
`;
